import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Form,
  Button,
  Card,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { apiUrl } from "../../utils/apiUrl";
import { CiEdit } from "react-icons/ci";
import AdminNavigation from "../AdminNavigation";

export default function CreateAdminPage() {
  const navigate = useNavigate();

  const [admins, setAdmins] = useState([]);
  const [logs, setLogs] = useState([]);
  const [newAdmin, setNewAdmin] = useState({
    email: "",
    role: "support",
  });

  const [showModal, setShowModal] = useState(false);
  const [adminToEdit, setAdminToEdit] = useState(null);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const adminsResponse = await axios.get(`${apiUrl}/admin/admins`, {
          withCredentials: true,
        });
        setAdmins(adminsResponse.data);

        const logsResponse = await axios.get(`${apiUrl}/admin/admin-logs`, {
          withCredentials: true,
        });
        setLogs(logsResponse.data);
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }
    };

    fetchAdminData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateAdmin = async () => {
    try {
      await axios.post(`${apiUrl}/admin/admins`, newAdmin, {
        withCredentials: true,
      });
      alert("New admin account created successfully");
      setNewAdmin({ email: "", role: "support" });
      const adminsResponse = await axios.get(`${apiUrl}/admin/admins`, {
        withCredentials: true,
      });
      setAdmins(adminsResponse.data);
    } catch (error) {
      console.error("Error creating admin account:", error);
    }
  };

  const handleUpdate = (admin) => {
    setAdminToEdit(admin); // Set admin details to be edited
    setShowModal(true); // Show the modal
  };

  const handleClose = () => {
    setShowModal(false); // Close the modal
    setAdminToEdit(null);
  };

  const handleRoleChange = (e) => {
    const { name, value } = e.target;
    setAdminToEdit((prev) => ({ ...prev, [name]: value }));
  };
  const handleSaveChanges = async () => {
    try {
      console.log(adminToEdit);

      await axios.patch(
        `${apiUrl}/admin/admins/${adminToEdit.id}/${adminToEdit.role}`,
        adminToEdit,
        {
          withCredentials: true,
        }
      );
      alert("Admin updated successfully");
      setShowModal(false); // Close the modal after saving
      const adminsResponse = await axios.get(`${apiUrl}/admin/admins`, {
        withCredentials: true,
      });
      setAdmins(adminsResponse.data); // Refresh the admin list
    } catch (error) {
      console.error("Error updating admin:", error);
    }
  };
  const handleDelete = async () => {
    try {
      console.log();

      await axios.delete(`${apiUrl}/admin/admins/${adminToEdit.id}`, {
        withCredentials: true,
      });
      alert("Admin deleted successfully");
      setShowModal(false); // Close the modal after deletion
      const adminsResponse = await axios.get(`${apiUrl}/admin/admins`, {
        withCredentials: true,
      });
      setAdmins(adminsResponse.data); // Refresh the admin list
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
  };

  return (
    <>
      {" "}
      <AdminNavigation />
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          restoreFocus={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {adminToEdit && (
              <>
                <Form.Group controlId="role">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name="role"
                    value={adminToEdit.role}
                    onChange={handleRoleChange}
                  >
                    <option value="" hidden></option>
                    <option value="superadmin">Superadmin</option>
                    <option value="verifier">Verifier</option>
                    <option value="support">Support</option>
                    <option value="Seo">Seo</option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete Admin
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Container className="my-5">
        <h2 className="text-center mb-4">Admin Management</h2>

        <section className="mb-5">
          <h3>Admin Accounts</h3>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin) => (
                <tr key={admin.id}>
                  <td className="flex flex-col justify-center items-center">
                    <img
                      className="w-[30px]"
                      src={admin.profileImage}
                      alt={admin.name}
                    />
                    {admin.name}
                  </td>
                  <td>{admin.email}</td>
                  <td>{admin.role}</td>
                  <td>
                    <CiEdit
                      onClick={() => handleUpdate(admin)}
                      className="text-[20px]"
                      style={{ color: "blue" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>

        <section className="mb-5">
          <h3>Create New Admin</h3>
          <Form className="mb-3">
            <Form.Group controlId="email">
              <Form.Control
                type="email"
                name="email"
                value={newAdmin.email}
                placeholder="Email"
                onChange={handleInputChange}
                className="mb-2"
              />
            </Form.Group>
            <Form.Group controlId="role">
              <Form.Select
                name="role"
                value={newAdmin.role}
                onChange={handleInputChange}
              >
                <option value="superadmin">Superadmin</option>
                <option value="verifier">Verifier</option>
                <option value="support">Support</option>
                <option value="Seo">Seo</option>
              </Form.Select>
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleCreateAdmin}
              className="mt-3"
            >
              Create Admin
            </Button>
          </Form>
        </section>

        <section className="mb-5">
          <h3>Admin Activity Logs</h3>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Admin</th>
                <th>Action</th>
                <th>Target ID</th>
                <th>Details</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr key={log.id}>
                  <td>{log.username}</td>
                  <td>{log.action}</td>
                  <td>{log.target_id}</td>
                  <td>{log.details}</td>
                  <td>{new Date(log.timestamp).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>
      </Container>
    </>
  );
}
