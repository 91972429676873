import React, { useEffect } from "react";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";

export default function ContactScreen() {
  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <Navigation />
      <div className="bg-gray-100 min-h-screen py-8">
        <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-xl">
          <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
            Contact Us
          </h1>
          <p className="text-lg text-gray-600 text-center mb-8">
            Last updated on Nov 17, 2024
          </p>

          <div className="space-y-4">
            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700">
                Merchant Legal Entity Name:
              </h2>
              <p className="text-gray-600">NIYAS K M</p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700">
                Registered Address:
              </h2>
              <p className="text-gray-600">
                2nd Star Building, Ponjassery, Ernakulam, Kerala 683547
              </p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700">
                Telephone No:
              </h2>
              <p className="text-gray-600">+91 8075540817</p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700">
                E-Mail ID:
              </h2>
              <p className="text-gray-600">stayforall.info@gmail.com</p>
            </div>

            <div className="mt-8 text-center">
              <a
                href="mailto:stayforall.info@gmail.com"
                className="border py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300"
              >
                Send Us an Email
              </a>
            </div>
          </div>
        </div>
      </div>
      <FooterTop top="top" bottom="bottom" />
    </>
  );
}
