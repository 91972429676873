import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Card,
  Container,
  ListGroup,
  Modal,
  Spinner,
} from "react-bootstrap";

function UserProfileInfo() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/admin/user/${userId}`, {
          withCredentials: true,
        });
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  const handleVerifyToggle = async () => {
    try {
      await axios.patch(`${apiUrl}/admin/user/${userId}/verify`, {
        withCredentials: true,
      });
      setUser({ ...user, isVerified: !user.isVerified });
    } catch (error) {
      console.error("Error toggling verification:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/admin/user/${userId}`, {
        withCredentials: true,
      });
      setShowDeleteModal(false);
      alert("User deleted successfully");
      navigate("/admin/users");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  if (loading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!user) return <div>No user data available</div>;

  return (
    <Container className="my-4">
      <Card className="shadow">
        <Card.Header as="h2" className="text-center">
          {user.name}
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>Email:</strong> {user.email}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Country:</strong> {user.nativeCountry}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>State:</strong> {user.preferredState}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Gender:</strong> {user.gender}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Verification Status:</strong>{" "}
              {user.isVerified ? "Verified" : "Unverified"}
            </ListGroup.Item>
          </ListGroup>

          <h3 className="mt-4">Documents</h3>
          <ListGroup>
            {user.documents?.map((doc) => (
              <ListGroup.Item key={doc.id}>
                {doc.type} -{" "}
                <span
                  className={doc.verified ? "text-success" : "text-warning"}
                >
                  {doc.verified ? "Verified" : "Pending"}
                </span>
              </ListGroup.Item>
            ))}
          </ListGroup>

          <div className="mt-4 d-flex justify-content-between">
            <Button
              variant={user.isVerified ? "secondary" : "primary"}
              onClick={handleVerifyToggle}
            >
              {user.isVerified ? "Unverify" : "Verify"} User
            </Button>
            <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
              Delete User
            </Button>
          </div>
        </Card.Body>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default UserProfileInfo;
