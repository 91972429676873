import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Form,
  InputGroup,
  Container,
  ListGroup,
  Pagination,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";

function UserList() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [filters, setFilters] = useState({
    country: "",
    visaType: "",
    state: "",
    isVerified: "",
    gender: "",
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/admin/user`, {
          params: { page, limit, ...filters, search },
          withCredentials: true,
        });
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [page, limit, filters, search]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <Container className="my-4">
      <h2 className="text-center mb-4">User List</h2>

      {/* Download user Data */}

      {/* Search and Filters */}
      <div>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="Search users..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button variant="primary" onClick={() => setPage(1)}>
            Search
          </Button>
        </InputGroup>
      </div>
      <Row className="mb-4 mt-3">
        <Col>
          <Form.Select
            placeholder="Country"
            name="state"
            value={filters.state}
            onChange={handleFilterChange}
          >
            <option value="" hidden>
              State
            </option>
            <option value="">All States</option>
            {[
              "Baden-Württemberg",
              "Bavaria",
              "Berlin",
              "Brandenburg",
              "Bremen",
              "Hamburg",
              "Hesse",
              "Lower Saxony",
              "Mecklenburg-Vorpommern",
              "North Rhine-Westphalia",
              "Rhineland-Palatinate",
              "Saarland",
              "Saxony",
              "Saxony-Anhalt",
              "Schleswig-Holstein",
              "Thuringia",
            ].map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            placeholder="Visa Type"
            name="visaType"
            value={filters.visaType}
            onChange={handleFilterChange}
          >
            <option value="" hidden>
              Visa Type
            </option>
            <option value="">All Visa Type</option>
            {[
              "student",
              "work",
              "tourist",
              "business",
              "family reunion",
              "transit",
              "diplomatic/official",
              "refugee/ asylum",
              "permanent",
              "investor/ entrepreneur",
              "exchange visitor",
            ].map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Group controlId="formRadioStatus">
            <div className="border rounded  p-2 flex items-center justify-between">
              <Form.Check
                type="radio"
                label="All"
                name="isVerified"
                value=""
                checked={filters.isVerified === ""}
                onChange={handleFilterChange}
              />
              <Form.Check
                type="radio"
                label="Verified"
                name="isVerified" // Must be the same for all radios in the group
                value="true" // The value for this option
                checked={filters.isVerified === "true"} // Controlled input
                onChange={handleFilterChange}
                className="mx-2"
              />
              <Form.Check
                type="radio"
                label="Unverified"
                name="isVerified"
                value="false"
                checked={filters.isVerified === "false"}
                onChange={handleFilterChange}
              />
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formRadioStatus">
            <div className="border rounded  p-2 flex items-center justify-between">
              <Form.Check
                type="radio"
                label="All"
                name="gender"
                value=""
                checked={filters.gender === ""}
                onChange={handleFilterChange}
              />
              <Form.Check
                type="radio"
                label="Male"
                name="gender" // Must be the same for all radios in the group
                value="male" // The value for this option
                checked={filters.gender === "male"} // Controlled input
                onChange={handleFilterChange}
                className="mx-2"
              />
              <Form.Check
                type="radio"
                label="Female"
                name="gender"
                value="female"
                checked={filters.gender === "female"}
                onChange={handleFilterChange}
              />
              <Form.Check
                type="radio"
                label="Others"
                name="gender"
                value="others"
                checked={filters.gender === "others"}
                onChange={handleFilterChange}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      {/* Loading Spinner */}
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <ListGroup>
          {users.length > 0 ? (
            users.map((user) => (
              <ListGroup.Item
                key={user.id}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <strong>{user.name}</strong> <br />
                  <span className="text-muted">{user.email}</span>
                </div>
                <Button
                  variant="link"
                  href={`/admin/user/${user.id}`}
                  className="text-decoration-none"
                >
                  View Profile
                </Button>
              </ListGroup.Item>
            ))
          ) : (
            <p className="text-center text-muted">No users found.</p>
          )}
        </ListGroup>
      )}

      {/* Pagination */}
      <Pagination className="justify-content-center mt-4">
        <Pagination.Prev
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Previous
        </Pagination.Prev>
        <Pagination.Item active>{page}</Pagination.Item>
        <Pagination.Next onClick={() => setPage(page + 1)}>
          Next
        </Pagination.Next>
      </Pagination>
    </Container>
  );
}

export default UserList;
