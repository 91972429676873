import React, { useState, useCallback, useEffect } from "react";
import { Container, Form, Button, Row, Col, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

export default function Media() {
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [youtubeLink, setYoutubeLink] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        if (file.type.startsWith("video/")) {
          if (!uploadedVideo) {
            setUploadedVideo(URL.createObjectURL(file));
            uploadFileToServer(file, "pdf");
          } else {
            alert("Only one video is allowed.");
          }
        } else if (file.type.startsWith("image/")) {
          if (uploadedImages.length < 8) {
            const newImage = {
              file,
              url: URL.createObjectURL(file),
            };
            setUploadedImages((prevImages) => [...prevImages, newImage]);
            uploadFileToServer(file, "image");
          } else {
            alert("You can only upload up to 8 images.");
          }
        }
      });
    },
    [uploadedVideo, uploadedImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*, image/jpeg, image/png",
    maxFiles: 9, // Limit for combined files, consider separate limits for clarity
  });

  const removeVideo = () => {
    setUploadedVideo(null);
  };

  const removeImage = (url) => {
    setUploadedImages((images) => images.filter((image) => image.url !== url));
  };

  useEffect(() => {
    // Cleanup URLs to avoid memory leaks
    return () => {
      if (uploadedVideo) {
        URL.revokeObjectURL(uploadedVideo);
      }
      uploadedImages.forEach((image) => URL.revokeObjectURL(image.url));
    };
  }, [uploadedVideo, uploadedImages]);

  const uploadFileToServer = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
  };

  const handleYoutubeLinkChange = (e) => {
    setYoutubeLink(e.target.value);
  };
  return (
    <div>
      {/* Media */}
      <h2>Media</h2>
      <h5>Video</h5>
      <p>Give potential tenants a video tour of your property.</p>
      <Form>
        <Form.Group controlId="formVideoUpload" className="mb-3">
          <Form.Label className="block mb-2">Upload a video</Form.Label>
          {!uploadedVideo ? (
            <div
              {...getRootProps()}
              className="border-dashed border-2 border-gray-300 p-4 mb-2 text-center cursor-pointer"
            >
              <input {...getInputProps()} />
              <Button variant="primary" className="mb-2">
                Upload Video
              </Button>
              <p>or drag it here</p>
            </div>
          ) : (
            <div className="mb-4">
              <h3 className="text-lg mb-2">Video Preview:</h3>
              <video width="100%" height="auto" controls>
                <source src={uploadedVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Button variant="danger" className="mt-2" onClick={removeVideo}>
                Remove Video
              </Button>
            </div>
          )}
        </Form.Group>

        <Form.Group controlId="formYoutubeVideo" className="mb-5">
          <Form.Label className="block mb-2">Youtube video</Form.Label>
          <Form.Control
            type="text"
            placeholder="www.youtube.com/watch?v=qdV1_MhaA_w"
            className="mb-2"
          />
          <Button variant="primary">Add Video</Button>
        </Form.Group>

        <h2 className="text-lg mb-4">Photos</h2>
        <p className="mb-4">
          Add stunning photos to your listing. You can add regular photos,
          360-degree photos, and floor plans.
        </p>

        <Form.Group controlId="formPhotoUpload" className="mb-3">
          <div
            {...getRootProps()}
            className="border-dashed border-2 border-gray-300 p-4 mb-2 text-center cursor-pointer"
          >
            <input {...getInputProps()} />
            <Button variant="primary" className="mb-2">
              Upload Photos
            </Button>
            <p>or drag them here</p>
          </div>

          <Row>
            {uploadedImages.map((image) => (
              <Col key={image.url} xs={6} md={4} lg={3} className="mb-3">
                <div className="position-relative">
                  <Image src={image.url} thumbnail />
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => removeImage(image.url)}
                    className="position-absolute top-0 right-0 m-1"
                  >
                    ×
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </Form.Group>

        <p className="text-sm text-gray-500">
          Use JPG or PNG images with a file size less than 12MB.
        </p>

        <h4>Permissions*</h4>
        <Form.Group>
          <Form.Label>What type of tenants would you prefer?</Form.Label>

          <Form.Check
            label={`I agree with the HousingAnywhere terms & conditions`}
          />
        </Form.Group>
      </Form>
    </div>
  );
}
