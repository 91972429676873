import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

export default function SpaceOverview({ Form, useState, navigate, query }) {
  const [formData, setFormData] = useState({
    propertySize: "",
    peopleCount: 0,
    description: "",
    bedroomCount: "",
    bedroomFurnished: "",
    lockOnBedroom: "",
  });

  // Load from localStorage when component mounts
  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // Save to localStorage whenever formData changes
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const updateFormData = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const validateForm = () => {
    const {
      propertySize,
      peopleCount,
      description,
      bedroomCount,
      bedroomFurnished,
      lockOnBedroom,
    } = formData;
    if (
      !propertySize ||
      !peopleCount ||
      !description ||
      !bedroomCount ||
      !bedroomFurnished ||
      !lockOnBedroom
    ) {
      alert("Please fill out all required fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Move to the next step in the flow
      query.set("step", 3);
      navigate(`/admin/roomListing?${query.toString()}`);
    }
  };

  const generateMonthsOptions = () => {
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    return months.map((m) => (
      <option key={m} value={`${m} months`}>{`${m} months`}</option>
    ));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <h3 className="mt-[30px]">Space Overview</h3>

        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Property Size m²*
          </Form.Label>
          <Form.Control
            value={formData.propertySize}
            onChange={(e) => updateFormData("propertySize", e.target.value)}
            className="border-0 shadow-none"
            type="number"
            placeholder="Enter property size"
            required
          />
        </Form.Group>

        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            How many people can rent this accommodation? *
          </Form.Label>
          <Form.Control
            value={formData.peopleCount}
            onChange={(e) => updateFormData("peopleCount", e.target.value)}
            className="border-0 shadow-none"
            type="number"
            placeholder="Enter number of people"
            required
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Description*
          </Form.Label>
          <Form.Control
            value={formData.description}
            onChange={(e) => updateFormData("description", e.target.value)}
            className="shadow-none h-[150px]"
            as="textarea"
            placeholder="Write down anything else you would like to mention about the property"
            required
          />
        </Form.Group>

        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Number of bedrooms*
          </Form.Label>
          <Form.Select
            value={formData.bedroomCount}
            onChange={(e) => updateFormData("bedroomCount", e.target.value)}
            className="border-0 shadow-none"
            required
          >
            <option value="">Select</option>
            <option value="Studio">Studio</option>
            {[1, 2, 3, 4, 5, 6, 7].map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
            <option value="8+">8+</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Property furnished*
          </Form.Label>
          <div key="Furnished-type" className="inline-flex">
            {["UnFurnished", "Semi Furnished", "Furnished"].map((e) => (
              <Form.Check
                key={e}
                inline
                type="radio"
                name="Furnished-type"
                className="border-0 shadow-none mx-2"
                label={e}
                value={e}
                checked={formData.bedroomFurnished === e}
                onChange={(e) =>
                  updateFormData("bedroomFurnished", e.target.value)
                }
                required
              />
            ))}
          </div>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Lock on bedroom*
          </Form.Label>
          <div key="Lock-on-bedroom-type" className="inline-flex">
            {["Yes", "No"].map((e) => (
              <Form.Check
                key={e}
                inline
                type="radio"
                name="Lock-on-bedroom-type"
                className="border-0 shadow-none mx-2"
                label={e}
                value={e}
                checked={formData.lockOnBedroom === e}
                onChange={(e) =>
                  updateFormData("lockOnBedroom", e.target.value)
                }
                required
              />
            ))}
          </div>
        </Form.Group>

        <Button type="submit">Continue</Button>
      </Form>
    </div>
  );
}
