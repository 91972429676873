import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { apiUrl } from "../../utils/apiUrl";
import { Container, Button, Card, Form } from "react-bootstrap";

export default function AnalyticsDashboard() {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");

  const handleExport = async () => {
    if (!startMonth || !endMonth) {
      alert("Please select both start and end months");
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/admin/export/users`, {
        params: { startDate: startMonth, endDate: endMonth },
        responseType: "blob",
        withCredentials: true, // Ensures the response is treated as a file
      });

      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting users:", error);
      // alert("Failed to export users data. Please try again.");
    }
  };

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const userGrowthResponse = await axios.get(
          `${apiUrl}/admin/analytics/user-growth`,
          { withCredentials: true }
        );
        const documentUploadsResponse = await axios.get(
          `${apiUrl}/admin/analytics/document-uploads`,
          { withCredentials: true }
        );
        const verificationTrendsResponse = await axios.get(
          `${apiUrl}/admin/analytics/verification-trends`,
          { withCredentials: true }
        );

        // Combine data by date for unified chart
        const combinedData = userGrowthResponse.data.growth.map((userEntry) => {
          const date = userEntry.date;
          const documentUploadEntry = documentUploadsResponse.data.find(
            (entry) => entry.date === date
          );
          const verificationTrendEntry =
            verificationTrendsResponse.data.userVerifications.find(
              (entry) => entry.date === date
            );

          return {
            date,
            new_users: userEntry.new_users,
            uploads: documentUploadEntry ? documentUploadEntry.uploads : 0,
            verifications: verificationTrendEntry
              ? verificationTrendEntry.count
              : 0,
          };
        });

        setAnalyticsData(combinedData);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    fetchAnalyticsData();
  }, []);

  const handleExportUsers = () => {
    window.location.href = `${apiUrl}/admin/export/users`;
  };

  // const handleExportDocuments = () => {
  //   window.location.href = `${apiUrl}/admin/export/documents`;
  // };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Analytics Dashboard</h2>

      <Card className="p-3 shadow-sm">
        <h3>Combined User Analytics Over Time</h3>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={analyticsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="new_users"
              stroke="#8884d8"
              name="New Users"
            />
            <Line
              type="monotone"
              dataKey="uploads"
              stroke="#82ca9d"
              name="Document Uploads"
            />
            <Line
              type="monotone"
              dataKey="verifications"
              stroke="#ff7300"
              name="Verifications"
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>

      <div className="text-center mt-4">
        <h2>Export Users Data</h2>
        <Form className="d-flex items-end justify-around border rounded p-2">
          <Form.Group>
            <Form.Label>Start Date:</Form.Label>
            <Form.Control
              type="month"
              value={startMonth}
              onChange={(e) => setStartMonth(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="md:mx-2">
            <Form.Label>End Date:</Form.Label>
            <Form.Control
              type="month"
              value={endMonth}
              onChange={(e) => setEndMonth(e.target.value)}
            />
          </Form.Group>
          <Button onClick={handleExport}>Export</Button>
        </Form>

        {/* <Button variant="success" onClick={handleExportDocuments}>
          Export Documents as CSV
        </Button> */}
      </div>
    </Container>
  );
}
