import { useNavigate } from "react-router-dom";
import { extractTime } from "../utils/extractTime";

export default function Message({
  t,
  message,
  receiverId,
  read,
  showStatusIcon,
}) {
  const navigate = useNavigate();

  const fromMe = message?.receiverId == receiverId;
  const formattedTime = extractTime(message.timestamp);
  const chatClassName = fromMe ? "chat-end" : "chat-start";
  const bubbleBgColor = fromMe
    ? "bg-[#28535e] rounded-bl-md"
    : "bg-[#707070] rounded-br-md";
  const shakeClass = message.shouldShake ? "shake" : "";

  // Function to determine if a media item is a video
  const isVideo = (url) => {
    if (typeof url !== "string") return false; // Ensure the URL is a string

    // Extract the file extension (without query parameters)
    const extension = url.split("?")[0].split(".").pop().toLowerCase();

    // Video file extensions to check
    const videoExtensions = ["mp4", "webm", "ogg"];

    return videoExtensions.includes(extension);
  };

  return (
    <div className={`chat ${chatClassName}`}>
      <div
        className={`chat-bubble ${bubbleBgColor} ${shakeClass} pb-2 px-3 text-white rounded-t-md`}
      >
        {Array.isArray(message.mediaUrl) &&
          message.mediaUrl.map((url, index) => (
            <div key={index} className="media-container mb-2">
              {isVideo(url) ? (
                <video
                  controls
                  src={url}
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              ) : (
                <img
                  src={url}
                  alt={`attachment-${index}`}
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                  onError={(e) => {
                    e.target.src = "/images/icons/media-fallback.png"; // Fallback image
                  }}
                  onClick={() => window.open(url, "_blank")}
                />
              )}
            </div>
          ))}
        <div className="flexpt-4">
          <span className="text-break">{message.message}</span>
          <span className="chat-footer opacity-50 text-[10px] flex justify-end p-0 ml-2">
            {formattedTime}
          </span>
        </div>
      </div>
      {fromMe && showStatusIcon && (
        <div className="flex items-center px-2">
          {read ? (
            <img
              className="w-[10px] mr-1"
              src="/images/icons/read.webp"
              alt="Read Icon"
            />
          ) : (
            <img
              className="w-[9px] mr-1"
              src="/images/icons/check.webp"
              alt="Sent Icon"
            />
          )}
          <span className="text-[11px]">
            {read ? t("chatPage.Seen") : t("chatPage.Send")}
          </span>
        </div>
      )}
    </div>
  );
}
