import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Form,
  Spinner,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DServiceDetail() {
  const { serviceName, id } = useParams();
  const navigate = useNavigate();
  const [serviceDetail, setServiceDetail] = useState(null);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiUrl}/admin/directService/${serviceName}/${id}`)
      .then((response) => {
        setServiceDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Failed to fetch service details. Please try again.");
        setLoading(false);
      });
  }, [serviceName, id]);

  const handleUpdate = () => {
    if (!response.trim()) {
      toast.error("Response cannot be empty!");
      return;
    }

    axios
      .put(`${apiUrl}/admin/directService/${serviceName}/${id}`, { response })
      .then(() => {
        toast.success("Service updated successfully!");
        setResponse("");
      })
      .catch((error) => {
        toast.error("Failed to update the service. Please try again.");
      });
  };

  const handleDelete = () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this service?"
    );
    if (!confirm) return;

    axios
      .delete(`${apiUrl}/admin/directService/${serviceName}/${id}`)
      .then(() => {
        toast.success("Service deleted successfully!");
        navigate(`/directService/${serviceName}`);
      })
      .catch((error) => {
        toast.error("Failed to delete the service. Please try again.");
      });
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  if (error)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <p className="text-danger">{error}</p>
      </div>
    );

  const {
    propertyType,
    verificationDate,
    verificationTime,
    selectedServices,
    detail,
    landLordNumber,
    streetName,
    unitNumber,
    postalCode,
    state,
    city,
  } = serviceDetail;

  return (
    <Container className="my-4">
      <ToastContainer />
      <Card className="p-4 shadow-lg">
        <h2 className="mb-4 text-center text-primary">Service Details</h2>
        <Row>
          <Col md={6}>
            <p>
              <strong>Property Type:</strong> {propertyType}
            </p>
            <p>
              <strong>Verification Date:</strong>{" "}
              {new Date(verificationDate).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </p>
            <p>
              <strong>Verification Time:</strong> {verificationTime}
            </p>
            <p>
              <strong>Selected Services:</strong>
            </p>
            <ul>
              {selectedServices.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          </Col>
          <Col md={6}>
            <p>
              <strong>Detail:</strong> {detail}
            </p>
            <p>
              <strong>Landlord Number:</strong> {landLordNumber}
            </p>
            <p>
              <strong>Street Name:</strong> {streetName}
            </p>
            <p>
              <strong>Unit Number:</strong> {unitNumber}
            </p>
            <p>
              <strong>Postal Code:</strong> {postalCode}
            </p>
            <p>
              <strong>State:</strong> {state}
            </p>
            <p>
              <strong>City:</strong> {city}
            </p>
          </Col>
        </Row>

        <Form className="d-flex align-items-center mt-4">
          <Form.Group className="flex-grow-1">
            <Form.Control
              className="shadow-sm"
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              type="text"
              placeholder="Enter your response"
            />
          </Form.Group>
          <Button className="ml-3" variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Form>

        <Button className="mt-3 w-100" variant="danger" onClick={handleDelete}>
          Delete Service
        </Button>
      </Card>
    </Container>
  );
}

export default DServiceDetail;
