import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

const AdminNavigation = () => {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex align-items-center cursor-pointer"
      onClick={() => navigate(-1)} // Navigates to the previous page
    >
      <IoMdArrowRoundBack className="mt-3 ml-3 text-[30px]" />
      <img
        src="/images/logo/SFAmediumLogo.webp"
        className="ml-2 w-[60px]"
        alt="SFA Logo"
      />
    </div>
  );
};

export default AdminNavigation;
