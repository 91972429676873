import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Spinner,
  ListGroup,
  Form,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";

export default function PendingVerifications() {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPendingVerifications = async () => {
      setLoading(true);
      setError(null);
      try {
        const [usersResponse, documentsResponse] = await Promise.all([
          axios.get(`${apiUrl}/admin/verifications/users`, {
            withCredentials: true,
          }),
          axios.get(`${apiUrl}/admin/verifications/documents`, {
            withCredentials: true,
          }),
        ]);
        setPendingUsers(usersResponse.data);
        setPendingDocuments(documentsResponse.data);
      } catch (error) {
        setError("Error fetching pending verifications");
        console.error("Error fetching pending verifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingVerifications();
  }, []);

  const toggleUserSelection = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const toggleDocumentSelection = (docId) => {
    setSelectedDocuments((prev) =>
      prev.includes(docId)
        ? prev.filter((id) => id !== docId)
        : [...prev, docId]
    );
  };

  const handleBatchVerify = async () => {
    try {
      await axios.patch(
        `${apiUrl}/admin/verifications/batch`,
        {
          users: selectedUsers,
          documents: selectedDocuments,
        },
        { withCredentials: true }
      );
      alert("Selected users and documents have been verified.");
      setPendingUsers((prev) =>
        prev.filter((user) => !selectedUsers.includes(user.id))
      );
      setPendingDocuments((prev) =>
        prev.filter((doc) => !selectedDocuments.includes(doc.id))
      );
      setSelectedUsers([]);
      setSelectedDocuments([]);
    } catch (error) {
      setError("Error in batch verification");
      console.error("Error in batch verification:", error);
    }
  };

  return (
    <Container className="my-4">
      <h2 className="text-center mb-4">Pending Verifications</h2>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <>
          {/* Pending Users */}
          <Row>
            <Col md={6}>
              <h3>Pending Users</h3>
              <ListGroup>
                {pendingUsers.length > 0 ? (
                  pendingUsers.map((user) => (
                    <ListGroup.Item key={user.id}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => toggleUserSelection(user.id)}
                        label={`${user.name} - ${user.email}`}
                      />
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No pending users for verification.</p>
                )}
              </ListGroup>
            </Col>

            {/* Pending Documents */}
            <Col md={6}>
              <h3>Pending Documents</h3>
              <ListGroup>
                {pendingDocuments.length > 0 ? (
                  pendingDocuments.map((doc) => (
                    <ListGroup.Item key={doc.id}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedDocuments.includes(doc.id)}
                        onChange={() => toggleDocumentSelection(doc.id)}
                        label={`${doc.type} - Uploaded by User ID ${doc.user_id}`}
                      />
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No pending documents for verification.</p>
                )}
              </ListGroup>
            </Col>
          </Row>

          {/* Batch Verification Button */}
          <div className="text-center my-4">
            <Button
              onClick={handleBatchVerify}
              disabled={!selectedUsers.length && !selectedDocuments.length}
              variant="primary"
            >
              Verify Selected
            </Button>
          </div>
        </>
      )}
    </Container>
  );
}
