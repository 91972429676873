import React, { useEffect, useRef, useState } from "react";
import "../../components.scss";
import { Link } from "react-router-dom";
import { IoChatboxOutline } from "react-icons/io5";
import { FaHandsClapping } from "react-icons/fa6";
import { messageNotification } from "../../../store/Actions/chat";
import Loading from "../../samplePage/Loading";
import { useSelector, useDispatch } from "react-redux";
import VerifiedStatus from "../../common/VerifiedStatus";

const MessageNotification = ({ t, navigate }) => {
  const dispatch = useDispatch();
  const searchContainerRef = useRef(null);
  const searchIconRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const { messageNotifications, loading } = useSelector(
    (state) => state.getMessageNotification
  );

  useEffect(() => {
    dispatch(messageNotification());
  }, [dispatch]);

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target) &&
      searchIconRef.current &&
      !searchIconRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleMessageNotification = (e) => {
    navigate(`/chat/${e.serviceKind}/${e.interestId}/${e.senderId}`);
  };

  const messageCount = messageNotifications?.reduce((result, item) => {
    if (item.unreadCount > 0) {
      return result + 1;
    }
    return result;
  }, 0);

  return (
    <>
      <div ref={searchContainerRef}>
        {visible && (
          <div className="navMessage-drop">
            {loading ? (
              <Loading type="colOne" color="#36d7b7" size={20} />
            ) : messageNotifications?.length === 0 ? (
              <div className="flex flex-column items-center py-4">
                <h5 className="flex text-secondary">
                  <FaHandsClapping className="mr-2" />
                  {t("navigation.No New Messages")}
                </h5>
              </div>
            ) : (
              <div className="p-2">
                {messageNotifications.map((e) => (
                  <div
                    key={e.id}
                    onClick={() => handleMessageNotification(e)}
                    className="flex items-center hover:shadow-sm p-1 pb-2 mb-2 cursor-pointer"
                  >
                    <img
                      className="rounded-circle w-[25px] h-[25px] mr-2"
                      src={e.senderProfileImage}
                      alt={e.senderName}
                    />
                    <div className="text-[#2e2e2e] text-[17px]">
                      {e.senderName}
                    </div>
                    <VerifiedStatus isVerified={e.isVerified} />
                    {e.unreadCount > 0 && (
                      <span className="text-[12px] text-white absolute bg-[#ff000098] px-[5px] rounded-circle translate-x-[-8px] translate-y-[-5px]">
                        {e.unreadCount}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <Link
        aria-label="Messages"
        to=""
        className="text-black cursor-pointer text-[24px] m-[12px]"
        ref={searchIconRef}
        onClick={() => setVisible(!visible)}
      >
        <IoChatboxOutline aria-label="message" className="search-icon" />
        {messageCount > 0 && (
          <span className="text-[12px] text-white absolute bg-[#ff000098] px-[5px] rounded-circle translate-x-4 translate-y-[-30px]">
            {messageCount}
          </span>
        )}
      </Link>
    </>
  );
};

export default MessageNotification;
