import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap"; // Assuming you are using React-Bootstrap

export default function Address({ navigate, query }) {
  // Manage all form data in one state object
  const [formData, setFormData] = useState({
    address1: "",
    address2: "",
    availableFrom: "",
    monthlyRent: "",
    minimumRentalPeriod: "",
    maximumRentalPeriod: "",
  });

  // Load from localStorage when component mounts
  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // Save to localStorage whenever formData changes
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const updateFormData = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  // Form validation
  const validateForm = () => {
    if (
      !formData.address1 ||
      !formData.address2 ||
      !formData.availableFrom ||
      !formData.monthlyRent
    ) {
      return false;
    }
    if (
      formData.minimumRentalPeriod &&
      formData.maximumRentalPeriod &&
      parseInt(formData.minimumRentalPeriod) >
        parseInt(formData.maximumRentalPeriod)
    ) {
      alert("Minimum rental period cannot be greater than the maximum period.");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      query.set("step", 2);
      navigate(`/admin/roomListing?${query.toString()}`);
    } else {
      alert("Please fill in all required fields.");
    }
  };

  const generateMonthsOptions = () => {
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    return months.map((m) => (
      <option key={m} value={`${m} months`}>{`${m} months`}</option>
    ));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <h3 className="mt-[30px]">Address</h3>
        <Form.Group className="border-bottom mb-2">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            City, country *
          </Form.Label>
          <Form.Control
            value={formData.address1}
            onChange={(e) => updateFormData("address1", e.target.value)}
            type="text"
            className="border-0 shadow-none"
            placeholder="Enter city and country"
            required
          />
        </Form.Group>
        <Form.Group className="border-bottom">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Street, house number *
          </Form.Label>
          <Form.Control
            value={formData.address2}
            onChange={(e) => updateFormData("address2", e.target.value)}
            type="text"
            className="border-0 shadow-none"
            placeholder="Enter street and house number"
            required
          />
        </Form.Group>

        <h3 className="mt-[60px]">Pricing and availability</h3>
        <Form.Group className="border-bottom mb-2">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Available from *
          </Form.Label>
          <Form.Control
            value={formData.availableFrom}
            onChange={(e) => updateFormData("availableFrom", e.target.value)}
            type="date"
            className="border-0 shadow-none"
            required
          />
        </Form.Group>
        <Form.Group className="border-bottom mb-2">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Monthly rent *
          </Form.Label>
          <Form.Control
            value={formData.monthlyRent}
            onChange={(e) => updateFormData("monthlyRent", e.target.value)}
            type="number"
            className="border-0 shadow-none"
            placeholder="Enter monthly rent"
            required
          />
        </Form.Group>

        <h3 className="mt-[60px]">Rental period</h3>
        <Form.Group className="border-bottom mb-2">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Minimum rental period *
          </Form.Label>
          <Form.Select
            value={formData.minimumRentalPeriod}
            onChange={(e) =>
              updateFormData("minimumRentalPeriod", e.target.value)
            }
            className="border-0 shadow-none"
            required
          >
            <option value="">No minimum</option>
            {generateMonthsOptions()}
          </Form.Select>
        </Form.Group>
        <Form.Group className="border-bottom mb-2">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Maximum rental period *
          </Form.Label>
          <Form.Select
            value={formData.maximumRentalPeriod}
            onChange={(e) =>
              updateFormData("maximumRentalPeriod", e.target.value)
            }
            className="border-0 shadow-none"
            required
          >
            <option value="">No maximum</option>
            {generateMonthsOptions()}
            <option value="1.5 years">1.5 years</option>
            <option value="2 years">2 years</option>
          </Form.Select>
        </Form.Group>

        <Button type="submit">Continue</Button>
      </Form>
    </div>
  );
}
