import React from "react";
import { Link } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";

export default function NavigationInD({ t }) {
  return (
    <div className="flex justify-between items-center px-4 py-2">
      <div>
        <Link aria-label="SFA" to="/">
          <img
            className="w-[60px]"
            src="/images/logo/SFAmediumLogo.webp"
            alt="SFA LOGO"
          />
        </Link>
      </div>
      <div className="flex hideFoot text-[15px]">
        <Link aria-label="Home" to="/" className="text-black mr-3">
          {t("inDirectServicePage.Home")}
        </Link>
        <Link
          aria-label="Offerings"
          to="/myProfile/find_work?service=Room"
          className="text-black mr-3"
        >
          {t("inDirectServicePage.Offerings")}
        </Link>
        <Link
          aria-label="Blog & Article"
          to="/blogs&article-Stayforall?page=1"
          className="text-black mr-3"
        >
          {t("inDirectServicePage.Blog & Article")}
        </Link>
      </div>
      <div className="flex">
        <Link aria-label="Account" to="/myProfile/profile">
          <VscAccount className="text-lightBlack text-[23px]" />
        </Link>
      </div>
    </div>
  );
}
