import React, { useEffect, useState } from "react";
import "../RoomListing.scss";
import Navigation from "../../../components/Navigation/Navigation";
import { reactUrl } from "../../../utils/apiUrl";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { roomListingData } from "../../../DataFiles/data";
import { Form, Table, Button } from "react-bootstrap";
import PropertySelect from "./components/PropertySelect";
import Address from "./components/Address";
import SpaceOverview from "./components/SpaceOverview";
import Areas from "./components/Areas";
import Amenities from "./components/Amenities";
import RentalConditions from "./components/RentalConditions";
import RulesAndPreferences from "./components/RulesAndPreferences";
import Media from "./components/Media";
import InitialVerification from "./components/InitialVerification";

export default function CreateRoomPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const queryStage = query.get("property") || "";
  const queryStep = query.get("step") || 0;

  // stage
  const [step, setStep] = useState("");
  const [property, setProperty] = useState("");

  //   Room details

  const handlePrevious = () => {
    query.set("step", Number(queryStep) - 1);
    navigate(`/admin/roomListing?${query.toString()}`);
  };
  const handleNext = () => {
    query.set("step", Number(queryStep) + 1);
    navigate(`/admin/roomListing?${query.toString()}`);
  };
  const handleComplete = () => {};

  useEffect(() => {
    document.documentElement.scrollTo({ top: "0", behavior: "instant" });
  }, []);

  useEffect(() => {
    query.set("step", queryStep);
    navigate(`/admin/roomListing?${query.toString()}`);
  }, [queryStep]);
  return (
    <>
      <div className="flex items-center justify-center">
        <div className="roomListingSteps mx-3 py-3">
          {queryStep == 0 && (
            <PropertySelect
              roomListingData={roomListingData}
              navigate={navigate}
            />
          )}

          {queryStep == 1 && (
            <Address
              Form={Form}
              useState={useState}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          )}
          {queryStep == 2 && (
            <SpaceOverview
              Form={Form}
              useState={useState}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          )}
          {queryStep == 3 && (
            <Areas
              Form={Form}
              useState={useState}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          )}
          {queryStep == 4 && (
            <Amenities
              Form={Form}
              useState={useState}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          )}
          {queryStep == 5 && (
            <RentalConditions
              Form={Form}
              Table={Table}
              useState={useState}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          )}
          {queryStep == 6 && (
            <RulesAndPreferences Form={Form} useState={useState} />
          )}
          {queryStep == 7 && (
            <Media Form={Form} Button={Button} useState={useState} />
          )}
          {queryStep == 8 && <InitialVerification />}
        </div>
      </div>

      {queryStep === "0" ? (
        ""
      ) : (
        <div className="w-[100vw] h-[60px] fixed bottom-0 flex items-center justify-end text-[18px] font-bold bg-[grey]">
          <button
            onClick={handlePrevious}
            className="bg-[white] p-1 px-3 rounded"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            type="submit"
            className="bg-[#5bb3b6] text-[white] p-1 px-3 mx-3 rounded"
          >
            Next
          </button>
        </div>
      )}
    </>
  );
}
