import React, { useEffect, useState } from "react";
import "../admin.scss";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import AnalyticsDashboard from "./AnalyticsDashboard";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import AdminNavigation from "../AdminNavigation";

export default function DashboardPage() {
  const [metrics, setMetrics] = useState({
    totalUsers: 0,
    recentUsers: [],
    verifiedUsers: 0,
    unverifiedUsers: 0,
    visaTypeCounts: [],
    totalDocuments: 0,
    verifiedDocuments: 0,
    pendingVerifications: 0,
    countryCounts: [],
    stateCounts: [],
    quickLinks: [],
  });

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/metrics`, {
          withCredentials: true,
        });
        setMetrics(response.data);
      } catch (error) {
        console.error("Error fetching dashboard metrics:", error);
      }
    };

    fetchMetrics();
  }, []);

  return (
    <>
      <AdminNavigation />
      <Container className="my-4">
        <h2 className="text-center mb-4">Dashboard Overview</h2>

        <AnalyticsDashboard />

        {/* User Metrics Section */}
        <Row className="mt-5">
          <Col md={4} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Total Users</Card.Title>
                <Card.Text>{metrics.totalUsers}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Verified Users</Card.Title>
                <Card.Text>{metrics.verifiedUsers}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Unverified Users</Card.Title>
                <Card.Text>{metrics.unverifiedUsers}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Visa Type Counts */}
        <Row className="mb-4">
          <Col>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Visa Types</Card.Title>
                <ListGroup variant="flush">
                  {metrics.visaTypeCounts?.map((visa) => (
                    <ListGroup.Item key={visa.visaType}>
                      {visa.visaType || "Not added"}: {visa.count}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Recent Sign-Ups */}
        <Row className="mb-4">
          <Col>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Recent Sign-Ups</Card.Title>
                <ListGroup variant="flush">
                  {metrics.recentUsers?.map((user) => (
                    <ListGroup.Item key={user.id}>
                      {user.name} ({user.email}) - Joined on{" "}
                      {new Date(user.created_at).toLocaleDateString()}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Document Metrics */}
        <Row className="mb-4">
          <Col md={4} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Total Documents</Card.Title>
                <Card.Text>{metrics.totalDocuments}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Verified Documents</Card.Title>
                <Card.Text>{metrics.verifiedDocuments}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Pending Verifications</Card.Title>
                <Card.Text>{metrics.pendingVerifications}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* User Counts by Country */}
        <Row className="mb-4">
          <Col>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>User Counts by Country</Card.Title>
                <ListGroup variant="flush">
                  {metrics.countryCounts?.map((country) => (
                    <ListGroup.Item key={country.nativeCountry}>
                      {country.nativeCountry || "Unknown"}: {country.count}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* User Counts by State */}
        <Row className="mb-4">
          <Col>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>User Counts by State</Card.Title>
                <ListGroup variant="flush">
                  {metrics.stateCounts?.map((state) => (
                    <ListGroup.Item key={state.preferredState}>
                      {state.preferredState || "Unknown"}: {state.count}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Quick Links */}
        <Row className="mb-4">
          <Col>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Quick Links</Card.Title>
                <ListGroup variant="flush">
                  {metrics.quickLinks?.map((link) => (
                    <ListGroup.Item key={link.path}>
                      <a className="text-black" href={link.path}>
                        {link.name}
                      </a>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
