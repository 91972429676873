import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import { Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import VerifiedStatus from "../../components/common/VerifiedStatus";

function DServiceRequests() {
  const { serviceName } = useParams();
  const [services, setServices] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/admin/directService/${serviceName}`)
      .then((response) => setServices(response.data))
      .catch((error) => console.error(error));
  }, [serviceName]);

  return (
    <Container>
      <h3 className="mt-4 mb-4 text-center">Service List for {serviceName}</h3>
      <Row className="">
        {services.map((service) => (
          <div
            className="flex space-between items-center border rounded p-3 mx-2"
            key={service.id}
          >
            <div>
              <div className="flex items-start text-[20px]">
                <img
                  className="w-[30px] mr-2"
                  src={service.profileImage}
                  alt="user"
                />
                <h3 className="flex">
                  {service.name || `Service ${service.id}`}
                  <VerifiedStatus isVerified={service.isVerified} />
                </h3>
              </div>
            </div>
            <div className="mx-5 text-center">
              <span className="font-bold mr-2">Visiting </span>
              <br />
              {format(new Date(service.verificationDate), "do MMMM yyyy")}
            </div>

            <Link
              className="border bg-primary text-white px-2 py-1 rounded shadow-sm"
              to={`/admin/DServiceDetail/${serviceName}/${service.id}`}
            >
              Details
            </Link>
          </div>
        ))}
      </Row>
    </Container>
  );
}

export default DServiceRequests;
