import React from "react";
import RoomView from "../../../components/Services/RoomView";
import RoomInterest from "../../../components/Interests/RoomInterest";
import AirportAssistanceView from "../../../components/Services/AirportAssistanceView";
import AirportAssistanceInterest from "../../../components/Interests/AirportAssistanceInterest";
import { confirmAlert } from "react-confirm-alert";

const componentsMap = {
  Room: {
    ServiceView: RoomView,
    InterestView: RoomInterest,
  },
  "Airport assistance": {
    ServiceView: AirportAssistanceView,
    InterestView: AirportAssistanceInterest,
  },
};

const ViewServiceInterest = ({
  serviceView,
  handleInterestView,
  accepted,
  category,
  handleServiceView,
  handleAcceptInterest,
  handleRejectInterest,
  interestView,
  interestError,
}) => {
  const submit = (title, message, label1, label2, clickHandler) => {
    try {
      confirmAlert({
        title,
        message,
        buttons: [
          { label: label1, onClick: clickHandler },
          { label: label2, onClick: () => console.log("Cancel clicked") }, // Optional logging
        ],
      });
    } catch (error) {
      console.error("Error displaying confirmation dialog:", error);
    }
  };

  const { ServiceView, InterestView } = componentsMap[category] || {};

  return (
    <>
      {ServiceView && (
        <ServiceView
          serviceView={serviceView}
          handleServiceId={handleServiceView}
        />
      )}
      {InterestView && (
        <InterestView
          submit={submit}
          interestView={interestView}
          handleInterestView={handleInterestView}
          handleAcceptInterest={handleAcceptInterest}
          handleRejectInterest={handleRejectInterest}
          accepted={accepted}
          interestError={interestError}
        />
      )}
    </>
  );
};

export default ViewServiceInterest;
