import React, { useState, useCallback, useEffect } from "react";
import { Container, Form, Button, Row, Col, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

export default function InitialVerification() {
  const [propertyOwnership, setPropertyOwnership] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState({
    businessRegistration: null,
    taxIdentificationNumber: null,
    safetyCertificate: null,
    buildingCompliance: null,
    liabilityInsurance: null,
  });

  const onDrop = useCallback((acceptedFiles, type) => {
    acceptedFiles.forEach((file) => {
      if (file.type === "application/pdf") {
        const fileURL = URL.createObjectURL(file);
        setUploadedFiles((prevFiles) => ({
          ...prevFiles,
          [type]: { file, fileURL },
        }));
        handleFileUpload(file, type);
      }
    });
  }, []);

  const handleFileUpload = async (file, type) => {
    const formData = new FormData();
    formData.append(type, file);
  };

  const removeUploadedFile = (type) => {
    if (uploadedFiles[type]) {
      URL.revokeObjectURL(uploadedFiles[type].fileURL);
      setUploadedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null,
      }));
    }
  };

  const FileUploadSection = ({ label, description, type }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, type),
      accept: "application/pdf",
      multiple: false,
    });

    return (
      <Form.Group controlId={`form${type}`} className="mb-3">
        <Form.Label className="block mb-2">{label}</Form.Label>
        <p>{description}</p>
        {!uploadedFiles[type] ? (
          <div
            {...getRootProps()}
            className="border-dashed border-2 border-gray-300 p-4 mb-2 text-center cursor-pointer"
          >
            <input {...getInputProps()} />
            <Button variant="primary" className="mb-2">
              Upload File (pdf)
            </Button>
            <p>or drag it here</p>
          </div>
        ) : (
          <div className="mb-4">
            <p>
              File uploaded successfully.{" "}
              <a
                href={uploadedFiles[type].fileURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                View File
              </a>
            </p>
            <Button
              variant="danger"
              className="mt-2"
              onClick={() => removeUploadedFile(type)}
            >
              Remove File
            </Button>
          </div>
        )}
      </Form.Group>
    );
  };

  return (
    <div>
      <h2>Initial Verification Requirements</h2>
      <h5>Property Ownership or Authorization</h5>
      <Form>
        <Form.Group>
          <div className="border rounded p-2">
            <Form.Check
              type="radio"
              id="ownership"
              label={
                <>
                  <strong>Property Ownership Documents</strong>
                  <p className="text-sm">
                    Proof of ownership, such as the property title deed or
                    property purchase contract.
                  </p>
                </>
              }
              name="ownershipType"
              value="ownership"
              checked={propertyOwnership === "ownership"}
              onChange={(e) => setPropertyOwnership(e.target.value)}
              className="mb-3"
            />
          </div>
          <div className="border rounded p-2 my-3">
            <Form.Check
              type="radio"
              id="lease"
              label={
                <>
                  <strong>Lease Agreement</strong>
                  <p className="text-sm">
                    If the host is renting the property, a copy of the lease
                    agreement to demonstrate permission to rent.
                  </p>
                </>
              }
              name="ownershipType"
              value="lease"
              checked={propertyOwnership === "lease"}
              onChange={(e) => setPropertyOwnership(e.target.value)}
              className="mb-3"
            />
          </div>
          <div className="border rounded p-2">
            <Form.Check
              type="radio"
              id="authorization"
              label={
                <>
                  <strong>Authorization Letter</strong>
                  <p className="text-sm">
                    A letter from the property owner authorizing the host to
                    rent out the property if they are not the owner.
                  </p>
                </>
              }
              name="ownershipType"
              value="authorization"
              checked={propertyOwnership === "authorization"}
              onChange={(e) => setPropertyOwnership(e.target.value)}
            />
          </div>
        </Form.Group>

        <h5>Legal Compliance</h5>
        <FileUploadSection
          label="Business Registration"
          description="Documentation showing that the host’s business is registered with the local trade office (Gewerbeanmeldung)"
          type="businessRegistration"
        />

        <FileUploadSection
          label="Tax Identification Number (TIN)"
          description="A copy of the host's tax identification number or VAT ID, as applicable for taxation purposes."
          type="taxIdentificationNumber"
        />

        <h5>Compliance with Local Regulations</h5>
        <FileUploadSection
          label="Safety Certificates"
          description="Certificates demonstrating compliance with local safety regulations, such as fire safety and electrical installations."
          type="safetyCertificate"
        />

        <FileUploadSection
          label="Building Compliance Documents"
          description="Documents indicating that the property complies with local building codes and regulations."
          type="buildingCompliance"
        />

        <h5>Insurance</h5>
        <FileUploadSection
          label="Liability Insurance"
          description="Proof of liability insurance coverage that protects against potential damages or incidents during a guest’s stay."
          type="liabilityInsurance"
        />
      </Form>
    </div>
  );
}
