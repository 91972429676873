import React, { useState } from "react";

export default function PropertySelect({ roomListingData, navigate }) {
  const [propertyType, setPropertyType] = useState("");

  const handleContinue = (type) => {
    setPropertyType(type);
    if (propertyType) {
      navigate(`/admin/roomListing?property=${type}&step=1`);
    } else {
      alert("Please select a property type to continue.");
    }
  };

  return (
    <div className="my-3 px-3 setFont">
      <h1>
        List your property on Stayforall.com and start welcoming guests in no
        time!
      </h1>
      <p>
        To get started, select the type of property you want to list on
        Stayforall.com
      </p>

      <div className="p-5 d-grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-5">
        {roomListingData.map((e) => (
          <div
            key={e.type}
            onClick={() => {
              handleContinue(e.type);
            }}
            className={`flex flex-col items-center shadow-md hover:shadow-lg p-2 rounded ${
              propertyType === e.type ? "border-[2px]" : ""
            }`}
          >
            <img
              className="w-[130px] h-[130px]"
              src={`/images/room_listing/${e.type}.webp`}
              alt={`${e.type}`}
            />
            <p className="font-bold">{e.type}</p>
            <p className="text-center text-[13px] text-secondary">
              {e.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
