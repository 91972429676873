import { useState } from "react";
import { BsSend } from "react-icons/bs";
import { FaPaperclip } from "react-icons/fa";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Form } from "react-bootstrap";
import Loading from "../../../components/samplePage/Loading";
import { LiaSmileBeam } from "react-icons/lia";

export default function MessageInput({
  t,
  sendMessageStatus,
  message,
  setMessage,
  showPicker,
  setShowPicker,
  handleEmojiSelect,
  handleSubmit,
  selectedFiles,
  setSelectedFiles,
}) {
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Add new files to existing state
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, fileIndex) => fileIndex !== index)
    );
  };

  return (
    <>
      {showPicker && (
        <div className="absolute bottom-[70px] left-[10px] z-20">
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
      {/* File Previews */}
      {selectedFiles.length > 0 && (
        <div className="mx-4 mt-2 flex flex-wrap gap-4">
          {selectedFiles.map((file, index) => (
            <div key={index} className="relative flex flex-col items-center">
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  className="w-20 h-20 object-cover rounded"
                />
              ) : (
                <video
                  src={URL.createObjectURL(file)}
                  className="w-20 h-20 object-cover rounded"
                  controls
                />
              )}
              <button
                onClick={() => handleRemoveFile(index)}
                className="absolute top-1 right-1 text-red-500 bg-white rounded-full px-[3px] text-sm"
                aria-label="Remove file"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      )}
      <Form
        onSubmit={handleSubmit}
        className="d-flex items-center justify-between px-4 py-3"
      >
        <Form.Group className="w-full">
          <div
            className="flex items-center rounded-lg p-1"
            style={{
              backgroundColor: "#fff",
            }}
          >
            {/* Emoji Picker Button */}
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowPicker((prev) => !prev);
              }}
              aria-label="Toggle emoji picker"
            >
              <LiaSmileBeam className="text-black text-2xl mx-1" />
            </button>
            {/* File Upload Button */}
            <button
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("fileInput").click();
              }}
              aria-label="Attach media"
            >
              <FaPaperclip className="text-black text-2xl mx-1" />
            </button>
            <input
              id="fileInput"
              type="file"
              accept="image/*,video/*"
              multiple // Allow multiple file selection
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            {/* Message Input */}
            <Form.Control
              as="textarea"
              rows={1}
              style={{
                backgroundColor: "#fff",
                border: "none",
                color: "black",
              }}
              className="shadow-none text-sm"
              type="text"
              placeholder={t("chatPage.Type a message")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              aria-label="Message input"
            />
          </div>
        </Form.Group>
        {/* Send Button */}
        <button
          variant=""
          type="submit"
          className="rounded-circle bg-[#83e2da] ml-2 p-[12px]"
        >
          {sendMessageStatus.loading ? (
            <Loading type="colTwo" color="#36d7b7" size={25} margin={0} />
          ) : (
            <BsSend className="text-[22px] text-[black]" />
          )}
        </button>
      </Form>
    </>
  );
}
