import React, { useState } from "react";

export default function Areas({ Form }) {
  const [kitchen, setKitchen] = useState("");
  const [toilet, setToilet] = useState("");
  const [bathroom, setBathroom] = useState("");
  const [livingRoom, setLivingRoom] = useState("");
  const [balcony, setBalcony] = useState("");
  const [garden, setGarden] = useState("");
  const [basement, setBasement] = useState("");
  const [parking, setParking] = useState("");
  const [wheelchairAccessible, setWheelchairAccessible] = useState("");
  const [allergyFriendly, setAllergyFriendly] = useState("");
  return (
    <div>
      {/* Areas */}
      <h3 className="mt-[30px]">Areas</h3>
      <Form>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Kitchen*
          </Form.Label>
          <br />
          <div key="Kitchen-Areas" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Kitchen-Areas-${e}`}
                value={e}
                onChange={(e) => setKitchen(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Toilet*
          </Form.Label>
          <br />
          <div key="Toilet-Areas" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Toilet-Areas-${e}`}
                value={e}
                onChange={(e) => setToilet(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Bathroom*
          </Form.Label>
          <Form.Select
            value={bathroom}
            onChange={(e) => setBathroom(e.target.value)}
            className="border-0 shadow-none"
          >
            {["No", "Private", "Male", "Female", "Mixed"].map((e) => (
              <option value={e}>{e}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Living room*
          </Form.Label>
          <br />
          <div key="" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Living-room-Areas-${e}`}
                value={e}
                onChange={(e) => setLivingRoom(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Balcony/Terrace*
          </Form.Label>
          <br />
          <div key="Balcony-Areas" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Balcony-Areas-${e}`}
                value={e}
                onChange={(e) => setBalcony(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Garden*
          </Form.Label>
          <br />
          <div key="Garden-Areas" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Garden-Areas-${e}`}
                value={e}
                onChange={(e) => setGarden(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Basement*
          </Form.Label>
          <br />
          <div key="Basement-Areas" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Basement-Areas-${e}`}
                value={e}
                onChange={(e) => setBasement(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Parking*
          </Form.Label>
          <br />
          <div key="Parking-Areas" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Parking-Areas-${e}`}
                value={e}
                onChange={(e) => setParking(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Wheelchair accessible *
          </Form.Label>
          <br />
          <div key="Wheelchair-accessible-Areas" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Wheelchair-accessible-Areas-${e}`}
                value={e}
                onChange={(e) => setWheelchairAccessible(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Allergy friendly*
          </Form.Label>
          <br />
          <div key="Allergy-friendly-Areas" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Allergy-friendly-Areas-${e}`}
                value={e}
                onChange={(e) => setAllergyFriendly(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}
