import { Dropdown, DropdownButton } from "react-bootstrap";
import React from "react";

export default function RentalConditions({ Form, Table, useState }) {
  // All items
  const [utilityCostItems, setUtilityCostItems] = useState([
    "Broadcasting fee",
  ]);
  const [additionalRequiredItems, setAdditionalRequiredItems] = useState([
    "Administration fee",
    "Cleaning",
    "Other",
  ]);
  const [optionalServiceItems, setOptionalServiceItems] = useState([
    "Bike rent",
    "Cleaning",
    "Early move-in",
    "Early move-out",
    "Final cleaning",
    "Full board",
    "Gym",
    "Half board",
    "Late move-in",
    "Late move-out",
    "Overnight guests",
    "Other optional costs",
    "Parking",
    "Towels/Bedding",
    "End-early fee",
  ]);
  const [depositItems, setDepositItems] = useState([
    "Security deposit",
    "Bedding deposit",
    "Towel deposit",
    "Other deposits",
  ]);

  // all costs
  const [contractType, setContractType] = useState("daily");
  const [cancellationPolicy, setCancellationPolicy] = useState("strict");
  const [additionalRequiredCosts, setAdditionalRequiredCosts] = useState([]);
  const [optionalServicesCosts, setOptionalServicesCosts] = useState([]);
  const [depositsCosts, setDepositsCosts] = useState([]);
  const [utilityCosts, setUtilityCosts] = useState([
    {
      type: "Electricity",
      included: "Included in rent",
      timeSpan: "",
      precise: "",
      amount: "",
    },
    {
      type: "Water",
      included: "Included in rent",
      timeSpan: "",
      precise: "",
      amount: "",
    },
    {
      type: "Gas",
      included: "Included in rent",
      timeSpan: "",
      precise: "",
      amount: "",
    },
    {
      type: "Internet",
      included: "Included in rent",
      timeSpan: "",
      precise: "",
      amount: "",
    },
  ]);

  // functions
  const addUtilityCosts = (eventKey) => {
    setUtilityCostItems((prevItems) => prevItems.filter((e) => e !== eventKey));
    setUtilityCosts((prevCosts) => [
      ...prevCosts,
      {
        type: eventKey,
        included: "",
        timeSpan: "",
        precise: "",
        amount: "",
      },
    ]);
  };
  const addAdditionalRequiredCosts = (eventKey) => {
    setAdditionalRequiredItems((prevItems) =>
      prevItems.filter((e) => e !== eventKey)
    );
    setAdditionalRequiredCosts((prevCosts) => [
      ...prevCosts,
      {
        type: eventKey,
        included: "Included in rent",
        timeSpan: "",
        precise: "",
        amount: "",
      },
    ]);
  };
  const addOptionalServicesCosts = (eventKey) => {
    setOptionalServiceItems((prevItems) =>
      prevItems.filter((e) => e !== eventKey)
    );
    setOptionalServicesCosts((prevCosts) => [
      ...prevCosts,
      {
        type: eventKey,
        included: "Included in rent",
        timeSpan: "",
        precise: "",
        amount: "",
      },
    ]);
  };
  const addDepositsCosts = (eventKey) => {
    setDepositItems((prevItems) => prevItems.filter((e) => e !== eventKey));
    setDepositsCosts((prevCosts) => [
      ...prevCosts,
      {
        type: eventKey,
        included: "Included in rent",
        timeSpan: "",
        precise: "",
        amount: "",
      },
    ]);
  };
  return (
    <div>
      {/* Rental conditions */}
      <h3>Rental conditions</h3>
      <h5 className="my-4">Contract type</h5>
      <Form>
        <Form.Group>
          <div className="border rounded p-2">
            <Form.Check
              type="radio"
              id="daily"
              label={
                <>
                  <strong>Daily</strong>
                  <p className="text-sm">
                    In case a tenant moves in or moves out in the middle of the
                    month, they will be charged for each day they stayed during
                    that month. For example: if the tenant moves in on the 28th
                    of August, they will pay for four days of rent in August.
                  </p>
                </>
              }
              name="contractType"
              value="daily"
              checked={contractType === "daily"}
              onChange={(e) => setContractType(e.target.value)}
              className="mb-3"
            />
          </div>
          <div className="border rounded p-2 my-3">
            <Form.Check
              type="radio"
              id="fortnightly"
              label={
                <>
                  <strong>Fortnightly</strong>
                  <p className="text-sm">
                    The tenant will pay half of the month's rent if they stay
                    less than two weeks in the month of move in/move out. For
                    example: if the tenant moves in on the 28th of August, they
                    will pay half of the rent for August.
                  </p>
                </>
              }
              name="contractType"
              value="fortnightly"
              checked={contractType === "fortnightly"}
              onChange={(e) => setContractType(e.target.value)}
              className="mb-3"
            />
          </div>
          <div className="border rounded p-2">
            <Form.Check
              type="radio"
              id="monthly"
              label={
                <>
                  <strong>Monthly</strong>
                  <p className="text-sm">
                    The tenant will always pay the entire month's rent,
                    regardless of the move-in/move-out date. For example: if the
                    tenant moves in on the 28th of August, they will pay for the
                    full month of August.
                  </p>
                </>
              }
              name="contractType"
              value="monthly"
              checked={contractType === "monthly"}
              onChange={(e) => setContractType(e.target.value)}
            />
          </div>
        </Form.Group>

        <h5 className="my-4">Cancellation Policy</h5>

        <Form.Group className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
          <div className="border rounded p-2">
            <Form.Check
              type="radio"
              id="strict"
              label={
                <>
                  <strong>Strict cancellation</strong>
                  <p className="text-sm">
                    If tenant cancels a booking:
                    <br />• Within 24 hours of confirmation – Full refund of
                    first month's rent
                    <br />• After 24 hours of confirmation – No refund
                  </p>
                </>
              }
              name="cancellationPolicy"
              value="strict"
              checked={cancellationPolicy === "strict"}
              onChange={(e) => setCancellationPolicy(e.target.value)}
              className="mb-3"
            />
          </div>

          <div className="border rounded p-2">
            <Form.Check
              type="radio"
              id="flexible"
              label={
                <>
                  <strong>Flexible cancellation</strong>
                  <p className="text-sm">
                    If tenant cancels a booking within 24 hours of confirmation
                    – Full refund of first month's rent.
                    <br />
                    If tenant cancels a booking when move-in date is:
                    <br />• More than 30 days away – Full refund of first
                    month's rent
                    <br />• 30 to 7 days away – 50% refund of first month's rent
                    <br />• Less than 7 days away – No refund
                  </p>
                </>
              }
              name="cancellationPolicy"
              value="flexible"
              checked={cancellationPolicy === "flexible"}
              onChange={(e) => setCancellationPolicy(e.target.value)}
            />
          </div>
        </Form.Group>

        {/* Utility costs */}
        <h5 className="my-4">Utility costs *</h5>
        <Form.Group>
          <Form.Label>
            Specify how much the tenant will pay per utility, how often, and if
            the cost is already included in the rent.
          </Form.Label>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Type</th>
                <th>Included in rent?</th>
                <th>How often?</th>
                <th>Estimate or exact?</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {utilityCosts.map((e, i) => (
                <tr key={i}>
                  <td>{e.type}</td>
                  <td>
                    <Form.Select value={e.included}>
                      <option value="Included in rent">Included in rent</option>
                      <option value="Paid Separately">Paid Separately</option>
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      value={e.timeSpan}
                      onChange={() => {}}
                      disabled={e.included == "Included in rent" ? true : false}
                    >
                      <option value="">Every month</option>
                      <option value="">Every 2 month</option>
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select value={e.precise}>
                      <option value="">Estimate</option>
                      <option value="">Exact</option>
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Group className="d-flex items-center border rounded bg-[white] px-2">
                      <Form.Label>EUR</Form.Label>
                      <Form.Control
                        type="text"
                        value={e.amount}
                        className="border-0 shadow-none"
                      />
                    </Form.Group>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form.Group>

        {utilityCostItems.length != 0 && (
          <DropdownButton
            variant=""
            onSelect={addUtilityCosts}
            className="border-0 shadow-none"
            title="+ Add new Cost"
          >
            {utilityCostItems.map((e, i) => (
              <Dropdown.Item eventKey={e} key={i}>
                {e}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}

        {/* Additional required costs */}
        <Form.Group className="mt-4">
          <h5>Additional required costs</h5>
          <p>
            Are there other costs that the tenant is required to pay? (e.g.,
            administration fee)
          </p>
          {additionalRequiredCosts.length != 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Included in rent?</th>
                  <th>How often?</th>
                  <th>Estimate or exact?</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {additionalRequiredCosts?.map((e, i) => (
                  <tr key={i}>
                    <td>{e.type}</td>
                    <td>
                      <Form.Select>
                        <option value="Included in rent">
                          Included in rent
                        </option>
                        <option value="Paid Separately">Paid Separately</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select>
                        <option value="">Every month</option>
                        <option value="">Every 2 month</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select>
                        <option value="">Estimate</option>
                        <option value="">Exact</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Group className="d-flex items-center border rounded bg-[white] px-2">
                        <Form.Label>EUR</Form.Label>
                        <Form.Control
                          type="text"
                          className="border-0 shadow-none"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Form.Group>
        {additionalRequiredItems.length != 0 && (
          <DropdownButton
            variant=""
            className="border-0 shadow-none"
            title="+ Add new Cost"
            onSelect={addAdditionalRequiredCosts}
          >
            {additionalRequiredItems.map((e, i) => (
              <Dropdown.Item key={i} eventKey={e}>
                {e}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}

        {/* Optional services costs */}
        <Form.Group className="mt-4">
          <h5>Optional services</h5>
          <p>
            Besides the costs above, are there optional services the tenant
            could choose to pay for? E.g., early move-in
          </p>
          {optionalServicesCosts.length != 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Included in rent?</th>
                  <th>How often?</th>
                  <th>Estimate or exact?</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {optionalServicesCosts?.map((e, i) => (
                  <tr key={i}>
                    <td>{e.type}</td>
                    <td>
                      <Form.Select>
                        <option value="Included in rent">
                          Included in rent
                        </option>
                        <option value="Paid Separately">Paid Separately</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select>
                        <option value="">Every month</option>
                        <option value="">Every 2 month</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select>
                        <option value="">Estimate</option>
                        <option value="">Exact</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Group className="d-flex items-center border rounded bg-[white] px-2">
                        <Form.Label>EUR</Form.Label>
                        <Form.Control
                          type="text"
                          className="border-0 shadow-none"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Form.Group>
        {addOptionalServicesCosts.length != 0 && (
          <DropdownButton
            variant=""
            onSelect={addOptionalServicesCosts}
            className="border-0 shadow-none"
            title="+ Add new Cost"
          >
            {optionalServiceItems.map((e, i) => (
              <Dropdown.Item eventKey={e} key={i}>
                {e}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}

        {/* Deposits */}
        <Form.Group className="mt-4">
          <h5>Deposits</h5>
          <p>Are you charging any deposits?</p>
          {depositsCosts.length != 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Included in rent?</th>
                  <th>How often?</th>
                  <th>Estimate or exact?</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {depositsCosts?.map((e, i) => (
                  <tr key={i}>
                    <td>{e.type}</td>
                    <td>
                      <Form.Select>
                        <option value="Included in rent">
                          Included in rent
                        </option>
                        <option value="Paid Separately">Paid Separately</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select>
                        <option value="">Every month</option>
                        <option value="">Every 2 month</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select>
                        <option value="">Estimate</option>
                        <option value="">Exact</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Group className="d-flex items-center border rounded bg-[white] px-2">
                        <Form.Label>EUR</Form.Label>
                        <Form.Control
                          type="text"
                          className="border-0 shadow-none"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Form.Group>
        {depositItems.length != 0 && (
          <DropdownButton
            variant=""
            onSelect={addDepositsCosts}
            className="border-0 shadow-none"
            title="+ Add new Cost"
          >
            {depositItems.map((e, i) => (
              <Dropdown.Item eventKey={e} key={i}>
                {e}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </Form>
    </div>
  );
}
