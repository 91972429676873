import React, { useState } from "react";

export default function Amenities({ Form }) {
  const [bed, setBed] = useState("");
  const [wiFi, setWiFi] = useState("");
  const [desk, setDesk] = useState("");
  const [closet, setCloset] = useState("");
  const [tV, setTV] = useState("");
  const [washingMachine, setWashingMachine] = useState("");
  const [dryer, setDryer] = useState("");
  const [dishwasher, setDishwasher] = useState("");
  const [kitchenware, setKitchenware] = useState("");
  const [heating, setHeating] = useState("");
  const [airConditioning, setAirConditioning] = useState("");
  const [flooring, setFlooring] = useState("");
  return (
    <div>
      {/* Amenities */}
      <h3 className="mt-[30px]">Amenities</h3>
      <Form>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Bed *
          </Form.Label>
          <br />
          <div key="Bed-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="Bed-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Bed-type-${e}`}
                value={e}
                onChange={(e) => setBed(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            WiFi *
          </Form.Label>
          <br />
          <div key="WiFi-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="WiFi-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`WiFi-type-${e}`}
                value={e}
                onChange={(e) => setWiFi(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Desk*
          </Form.Label>
          <br />
          <div key="Desk-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="Desk-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Desk-type-${e}`}
                value={e}
                onChange={(e) => setDesk(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Closet*
          </Form.Label>
          <br />
          <div key="Closet-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="Closet-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Closet-type-${e}`}
                value={e}
                onChange={(e) => setCloset(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            TV*
          </Form.Label>
          <br />
          <div key="TV-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="TV-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`TV-type-${e}`}
                value={e}
                onChange={(e) => setTV(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Washing machine*
          </Form.Label>
          <br />
          <div key="Washing-machine-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="Washing-machine-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Washing-machine-type-${e}`}
                value={e}
                onChange={(e) => setWashingMachine(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Dryer*
          </Form.Label>
          <br />
          <div key="Dryer-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="Dryer-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Dryer-type-${e}`}
                value={e}
                onChange={(e) => setDryer(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Dishwasher*
          </Form.Label>
          <br />
          <div key="Dishwasher-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="Dishwasher-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Dishwasher-type-${e}`}
                value={e}
                onChange={(e) => setDishwasher(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Kitchenware *
          </Form.Label>
          <br />
          <div key="Kitchenware-type" className="inline-flex">
            {["No", "Shared", "Private"].map((e) => (
              <Form.Check
                type="radio"
                name="Kitchenware-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Kitchenware-type-${e}`}
                value={e}
                onChange={(e) => setKitchenware(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Heating*
          </Form.Label>
          <Form.Select
            value={heating}
            onChange={(e) => setHeating(e.target.value)}
            className="border-0 shadow-none"
          >
            <option value="">Select</option>
            {["No", "Central heating", "Gas", "Wood stove", "Electrical"].map(
              (e) => (
                <option value={e}>{e}</option>
              )
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Air conditioning*
          </Form.Label>
          <br />
          <div key="Air-conditioning-type" className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                name="Air-conditioning-type"
                className="border-0 shadow-none mx-2"
                label={e}
                id={`Air-conditioning-${e}`}
                value={e}
                onChange={(e) => setAirConditioning(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Flooring*
          </Form.Label>
          <Form.Select
            value={flooring}
            onChange={(e) => setFlooring(e.target.value)}
            className="border-0 shadow-none"
          >
            <option value="">Select</option>
            {["Laminate", "Carpet", "Stone", "Wood", "Plastic", "Other"].map(
              (e) => (
                <option value={e}>{e}</option>
              )
            )}
          </Form.Select>
        </Form.Group>
      </Form>
    </div>
  );
}
