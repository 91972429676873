import React, { useState } from "react";

export default function RulesAndPreferences({ Form }) {
  const [gender, setGender] = useState("");
  const [minimumAge, setMinimumAge] = useState("");
  const [maximumAge, setMaximumAge] = useState("");
  const [tenantType, setTenantType] = useState("");
  const [couples, setCouples] = useState("");
  const [anmeldung, setAnmeldung] = useState("");
  const [pets, setPets] = useState("");
  const [musicalInstruments, setMusicalInstruments] = useState("");
  const [smoking, setSmoking] = useState("");

  const ageOptions = [
    "No Preference",
    ...Array.from({ length: 32 }, (_, i) => (18 + i).toString()),
    "50+",
  ];

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedDocuments((prev) =>
      prev.includes(value)
        ? prev.filter((doc) => doc !== value)
        : [...prev, value]
    );
  };
  console.log(selectedDocuments);
  return (
    <div>
      {/* Rules and Preferences */}
      <h3>Rules and Preferences</h3>
      <Form className="mt-4">
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Do your preferred tenants have a specific gender?
          </Form.Label>
          <br />
          <div className="inline-flex">
            {["male", "Female", "No preference"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={e}
                name="gender"
                value={e}
                onChange={(e) => setGender(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            What's the minimum age of your preferred tenants?
          </Form.Label>
          <Form.Select
            value={minimumAge}
            onChange={(e) => setMinimumAge(e.target.value)}
            className="border-0 shadow-none"
          >
            {ageOptions.map((e) => (
              <option value={e}>{e}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="border-bottom mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            What's the maximum age of your preferred tenants?
          </Form.Label>

          <Form.Select
            value={maximumAge}
            onChange={(e) => setMaximumAge(e.target.value)}
            className="border-0 shadow-none"
          >
            {ageOptions.map((e) => (
              <option value={e}>{e}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            What type of tenants would you prefer?
          </Form.Label>
          <br />
          <div className="inline-flex">
            {["Any", "Students only", "working professionals only"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={e}
                name="tenantType"
                value={e}
                onChange={(e) => setTenantType(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Are couples allowed to rent your property?
          </Form.Label>
          <br />
          <div className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={e}
                name="couplesAllowed"
                value={e}
                onChange={(e) => setCouples(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Can tenants register at your property’s address? (anmeldung)
          </Form.Label>
          <br />
          <div className="inline-flex">
            {["No", "Yes"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={e}
                name="anmeldung"
                value={e}
                onChange={(e) => setAnmeldung(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Are your tenants allowed to have pets in your property?
          </Form.Label>
          <br />
          <div className="inline-flex">
            {["No", "Yes", "Negotiable"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={e}
                name="pets"
                value={e}
                onChange={(e) => setPets(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Can your tenants play musical instruments in your property?
          </Form.Label>
          <br />
          <div className="inline-flex">
            {["No", "Yes", "Negotiable"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={e}
                name="music_instruments"
                value={e}
                onChange={(e) => setMusicalInstruments(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            Are your tenants allowed to smoke in your property?
          </Form.Label>
          <br />
          <div className="inline-flex">
            {["No", "Yes", "Negotiable", "Outside only"].map((e) => (
              <Form.Check
                type="radio"
                className="border-0 shadow-none mx-2"
                label={e}
                id={e}
                name="smoke"
                value={e}
                onChange={(e) => setSmoking(e.target.value)}
              />
            ))}
          </div>
        </Form.Group>

        <h3 className="my-4">Documents from tenant</h3>
        <p>
          Select what documents you need from the tenant to accept their booking
          request. If you don't select any option now, you can still ask the
          tenant for these documents later when the booking is confirmed.
        </p>

        <Form.Group className="mb-4">
          <Form.Label className="text-secondary text-[15px] p-0 m-0">
            What type of tenants would you prefer?
          </Form.Label>
          {[
            {
              title: "Proof of identity",
              items: `Government issued ID, passport, driver’s license`,
            },
            {
              title: "Proof of occupation or enrollment",
              items: `University enrollment certificate, internship or employment contract.`,
            },
            {
              title: "Proof of income",
              items: `Salary slip or bank statements from the tenant or their sponsor.`,
            },
          ].map((e, i) => (
            <>
              <Form.Check
                className="mb-4"
                type="checkbox"
                label={
                  <>
                    {e.title}
                    <br />
                    {e.items}
                  </>
                }
                key={i}
                id={e.title}
                value={e.title}
                onChange={handleCheckboxChange}
              />
            </>
          ))}
        </Form.Group>

        <h4>Permissions*</h4>
        <Form.Group className="mb-4">
          <Form.Check
            type="checkbox"
            label={`I agree that I will only use the required documents to create a rental agreement. I will destroy the copies within 48 hours if the person won't be renting the property. Read HousingAnywhere’s Privacy Policy`}
          />
        </Form.Group>
      </Form>
    </div>
  );
}
