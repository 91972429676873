import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import { Link, useParams } from "react-router-dom";
import { Card, Container, Row, Col, Spinner } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import VerifiedStatus from "../../components/common/VerifiedStatus";
import { format } from "date-fns";

function DServiceOverview() {
  const [overview, setOverview] = useState(null);
  const { serviceName } = useParams();

  useEffect(() => {
    axios
      .get(`${apiUrl}/admin/directService/dashboard/${serviceName}`)
      .then((response) => setOverview(response.data))
      .catch((error) => console.error(error));
  }, [serviceName]);

  if (!overview)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  const pieData = {
    labels: overview.dServicesByState.map((state) => state.state),
    datasets: [
      {
        data: overview.dServicesByState.map((state) => state.count),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4CAF50",
          "#FF9800",
          "#9C27B0",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4CAF50",
          "#FF9800",
          "#9C27B0",
        ],
      },
    ],
  };

  return (
    <Container className="my-4">
      <h2 className="text-center text-primary mb-4">Dashboard Overview</h2>

      <Row className="mb-4">
        <Col md={4}>
          <Card className="shadow-sm text-center p-3 border-primary">
            <h4>Total Services</h4>
            <h2 className="text-primary">{overview.totalDServices}</h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow-sm text-center p-3 border-success">
            <h4>Completed Services</h4>
            <h2 className="text-success">{overview.completedDServices}</h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow-sm text-center p-3 border-warning">
            <h4>Pending Services</h4>
            <h2 className="text-warning">{overview.pendingDServices}</h2>
          </Card>
        </Col>
      </Row>

      <Card className="shadow-sm p-4">
        <h3 className="text-center text-secondary mb-4">Services by State</h3>
        <Row>
          <Col md={6}>
            <ul className="list-unstyled">
              {overview.dServicesByState.map((state) => (
                <li key={state.state} className="mb-2">
                  <strong>{state.state}:</strong> {state.count}
                </li>
              ))}
            </ul>
          </Col>
          <Col md={6}>
            <Pie data={pieData} />
          </Col>
        </Row>
      </Card>

      {overview.queryDServiceAndUser.map((service) => (
        <div
          className="flex space-between items-center border rounded p-3 mx-2"
          key={service.id}
        >
          <div>
            <div className="flex items-start text-[20px]">
              <img
                className="w-[30px] mr-2"
                src={service.profileImage}
                alt="user"
              />
              <h3 className="flex">
                {service.name || `Service ${service.id}`}
                <VerifiedStatus isVerified={service.isVerified} />
              </h3>
            </div>
          </div>
          <div className="mx-5 text-center">
            <span className="font-bold mr-2">Visiting </span>
            <br />
            {format(new Date(service.verificationDate), "do MMMM yyyy")}
          </div>

          <Link
            className="border bg-primary text-white px-2 py-1 rounded shadow-sm"
            to={`/admin/DServiceDetail/${serviceName}/${service.id}`}
          >
            Details
          </Link>
        </div>
      ))}
    </Container>
  );
}

export default DServiceOverview;
