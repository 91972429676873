import React, { useEffect } from "react";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";

export default function CancellationandRefund() {
  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <Navigation />
      <div className="bg-gray-100 min-h-screen pt-20 pb-8">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-xl">
          <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
            Cancellation and Refund Policy
          </h1>
          <p className="text-lg text-center text-gray-600 mb-8">
            Last updated on Nov 17, 2024
          </p>

          <div className="space-y-6 text-gray-700">
            <p>
              <strong className="font-semibold">Stayforall</strong> specializes
              in providing Room Verification services, where we ensure that the
              accommodation meets quality standards before you commit. Our
              service includes a detailed review of the property and, upon
              request, a live video call to show the property.
            </p>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Cancellation Conditions:
              </h2>
              <p>
                Cancellations will be considered only if the request is made
                within 2-3 days of booking the Room Verification service. Once
                the verification process begins (i.e., the review is done or the
                video call has been scheduled), cancellation requests may not be
                entertained. In the case of cancellation initiated by the
                customer, a small transaction fee and processing charge will be
                deducted from the total amount.
              </p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Full Refund Policy:
              </h2>
              <p>
                A full refund will be initiated only if we are unable to verify
                the property due to unforeseen circumstances or technical issues
                on our side (e.g., inability to conduct the video call upon
                customer request or verify the accommodation). If the
                verification process is successfully completed, no refunds will
                be issued.
              </p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Refund Process:
              </h2>
              <p>
                Once a refund is approved, it will take 16-30 days to process
                and credit the amount back to the customer's account. For
                cancellations initiated by the customer, a transaction fee and
                processing charge will be deducted from the total amount of the
                refund.
              </p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Customer Expectations:
              </h2>
              <p>
                If the Room Verification service did not meet your expectations,
                or if you were unable to view the property due to technical
                issues on our side, please notify our Customer Service team
                within 2-3 days. We will investigate the issue and take
                appropriate action, which may include a full refund if it is
                determined that the service was not provided as promised.
              </p>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Service Complaints:
              </h2>
              <p>
                If you have any complaints regarding the Room Verification
                service itself (e.g., the verification process was delayed or
                the video call was not conducted as agreed), please contact our
                Customer Service team. We will review the issue and take
                necessary action to resolve it, which may include offering a
                refund or re-trying the verification.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterTop top="top" bottom="bottom" />
    </>
  );
}
